<template>
  <section>
    <section class="navBack">
      <v-container class="container-custom">
        <v-row>
          <v-col md="12" class="topNav">
            <ul class="navList">
              <li>Home</li>
              <li v-if="$route.query.isSecondHand"><v-icon> mdi-chevron-right </v-icon> Second Hand Vehicles</li>
              <li v-if="$route.query.category"><v-icon> mdi-chevron-right </v-icon> {{$route.query.category}}</li>
              <li v-if="$route.query.brand"><v-icon> mdi-chevron-right </v-icon> {{$route.query.brand}}</li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="my-5 mb-15">
      <v-container class="container-custom">
        <v-row>
          <v-col md="3" v-if="filterloading" cols="12">
            <LoadingSkeleton :image="5" />
          </v-col>
          <v-col md="3" v-if="!filterloading" cols="12">
            <div
              class="filterationBox"
              v-if="filters.category.items.length > 0"
            >
              <header>
                {{ filters.category.title }}
                <v-icon @click="toggleBoxAct()"> mdi-minus </v-icon>
              </header>
              <ul class="filterBox" :class="{ toogleBox: toggleBox }">
                <li
                  v-for="(data, index) in filters.category.items"
                  :key="index + 'category'"
                >
                  <v-checkbox
                    @change="filterProduct"
                    v-model="category"
                    :value="data.value"
                    :label="data.name"
                  ></v-checkbox>
                </li>
              </ul>
            </div>
            <div class="filterationBox" v-if="filters.brand.items.length > 0">
              <header>
                {{ filters.brand.title }}
                <v-icon @click="toggleBoxAct()"> mdi-minus </v-icon>
              </header>
              <ul class="filterBox" :class="{ toogleBox: toggleBox }">
                <li
                  v-for="(data, index) in filters.brand.items"
                  :key="index + 'category'"
                >
                  <v-checkbox
                    @change="filterProduct"
                    v-model="brand"
                    :value="data.value"
                    :label="data.name"
                  ></v-checkbox>
                </li>
              </ul>
            </div>
            <div
              class="filterationBox"
              v-if="filters.bodyType.items.length > 0"
            >
              <header>
                {{ filters.bodyType.title }}
                <v-icon @click="toggleBoxAct()"> mdi-minus </v-icon>
              </header>
              <ul class="filterBox" :class="{ toogleBox: toggleBox }">
                <li
                  v-for="(data, index) in filters.bodyType.items"
                  :key="index + 'category'"
                >
                  <v-checkbox
                    @change="filterProduct"
                    v-model="bodyType"
                    :value="data.value"
                    :label="data.name"
                  ></v-checkbox>
                </li>
              </ul>
            </div>
            <div class="filterationBox" v-if="filters.price.items != undefined">
              <header>
                {{ filters.price.title }}
                <v-icon @click="toggleBoxAct()"> mdi-minus </v-icon>
              </header>
              <ul class="filterBox" :class="{ toogleBox: toggleBox }">
                <li
                  v-for="(data, index) in filters.price.items"
                  :key="index + 'category'"
                >
                  <v-checkbox
                    @change="filterProduct"
                    v-model="price"
                    :value="data.value"
                    :label="data.name"
                  ></v-checkbox>
                </li>
              </ul>
            </div>
          </v-col>
          <v-col md="9" cols="12">
            <div>
              <v-row v-if="loading">
                <v-col v-for="n in 3" :key="n" md="4" cols="6">
                  <LoadingSkeleton :image="2" :title="2" :text="6" />
                </v-col>
              </v-row>
              <v-row v-if="!loading">
                <v-col v-if="total == 0">
                  <div class="noProductAvailable">No Product Available</div>
                </v-col>
              </v-row>
              <v-row v-if="!loading">
                <v-col
                  md="4"
                  cols="6"
                  v-for="(data, index) in products"
                  :key="index + 'product'"
                >
                  <ProductCard :item="data" />
                </v-col>
              </v-row>
              <v-row v-if="next">
                <v-col
                  md="12"
                  class="text-center viewMore mt-5"
                  v-if="!loadMore"
                >
                  <v-btn large @click="nextAct"
                    >Load More <v-icon> mdi-chevron-right </v-icon
                    ><v-icon> mdi-chevron-right </v-icon></v-btn
                  >
                </v-col>
                <v-col
                  md="12"
                  class="text-center viewMore mt-5"
                  v-if="loadMore"
                >
                  <v-btn large loading></v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
import ProductCard from "@/components/ProductCard";
import LoadingSkeleton from "@/components/LoadingSkeleton";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Home",
  data: () => ({
    filters: [],
    toggleBox: false,
    limit: 12,
    page: 1,
    products: [],
    loading: true,
    filterloading: true,
    category: undefined,
    brand: undefined,
    bodyType: undefined,
    price: undefined,
    next: false,
    total: 1,
    filterOption: {},
    loadMore: false,
  }),
  computed: {
    ...mapGetters(["allFilters", "allProducts"]),
  },
  methods: {
    ...mapActions(["getFilters", "getFrotendProducts"]),
    toggleBoxAct() {
      this.toggleBox = !this.toggleBox;
    },
    async filterProduct() {
      this.filterloading = true;
      this.loading = true;
      if (this.category != undefined) {
        this.filterOption.category = this.category;
      }
      if (this.brand != undefined) {
        this.filterOption.brand = this.brand;
      }
      if (this.price != undefined) {
        this.filterOption.price = this.price;
      }
      if (this.bodyType != undefined) {
        this.filterOption.bodyType = this.bodyType;
      }
      await this.getFilters(this.filterOption);
      this.filterloading = false;
      this.filters = this.allFilters.results;
      await this.getFrotendProducts(this.filterOption);
      this.products = this.allProducts.results;
      this.next = this.allProducts.pagination.next != undefined ? true : false;
      this.total = this.allProducts.pagination.total;
      this.loading = false;
    },
    async nextAct() {
      this.loadMore = true;
      await this.getFrotendProducts({
        ...this.filterOption,
        limit: this.limit,
        page: this.allProducts.pagination.next,
      });
      this.allProducts.results.forEach((ele) => {
        this.products.push(ele);
      });
      this.loadMore = false;
      this.next = this.allProducts.pagination.next != undefined ? true : false;
    },
  },
  components: {
    ProductCard,
    LoadingSkeleton,
  },
  async created() {
    let filter = {};
    if (this.$route.query.isSecondHand) {
      filter.isSecondHand = this.$route.query.isSecondHand;
    }
    if (this.$route.query.category) {
      filter.category = this.$route.query.category;
      this.category = this.$route.query.category;
    }
    if (this.$route.query.brand) {
      filter.brand = this.$route.query.brand;
      this.brand = this.$route.query.brand;
    }
    if (this.$route.query.year) {
      filter.year = this.$route.query.year;
    }
    if (this.$route.query.bodyType) {
      filter.bodyType = this.$route.query.bodyType;
      this.bodyType = this.$route.query.bodyType;
    }
    if (this.$route.query.year) {
      filter.year = this.$route.query.year;
    }
    await this.getFilters(filter);
    this.filterloading = false;
    this.filters = this.allFilters.results;
    await this.getFrotendProducts({
      limit: this.limit,
      page: this.page,
      ...filter,
    });
    this.products = this.allProducts.results;
    this.next = this.allProducts.pagination.next != undefined ? true : false;
    this.total = this.allProducts.pagination.total;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.navBack {
  background: #f4f4f4;
  padding: 10px 0;
}
.v-btn {
  min-height: 5 0px;
  width: 200px;
  color: #0878d4 !important;
  background: #fff !important;
  border: 1px solid #0878d4;
  box-shadow: none !important;
}
.noProductAvailable {
  font-size: 18px;
}
.filterationBox {
  border: 1px solid #efefef;
  padding: 20px 20px 10px;
  margin-bottom: 20px;
  header {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    font-weight: 500;
  }
  .toogleBox {
    display: none;
  }
  .filterBox {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 10px;
    li {
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: -15px;
      div {
        margin-top: -15px;
      }
    }
  }
}
.v-input {
  margin-top: 0 !important;
}
.navList {
  padding-left: 0;
  list-style: none;
  display: flex;
  font-size: 14px;
  li {
    .v-icon {
      font-size: 18px;
      margin-left: 5px;
    }
    // margin-right: 15px;
  }
}
</style>