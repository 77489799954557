<template>
  <section>
    <div class="productCardTop">
      <div class="danger">
        <v-chip v-if="item.discountPercentage != 0" small
          >Save Rs. {{ numberFormat(item.discountPercentage * 100) }}</v-chip
        >
      </div>

      <router-link :to="`/product/${item._id}`">
        <img :src="item.medias.mediaLink" alt="" />
      </router-link>
      <v-icon
        class="heart"
        v-if="!loading && !item.wishlist"
        @click="addToWishlist"
      >
        mdi-heart-outline
      </v-icon>
      <v-icon
        class="heart filled"
        v-if="!loading && item.wishlist"
        @click="addToWishlist"
      >
        mdi-heart
      </v-icon>
      <v-progress-circular
        indeterminate
        v-if="loading"
        size="23"
        class="heart loadingIcon"
        width="2"
      ></v-progress-circular>
    </div>
    <div class="productCardBody">
      <h4>
        <router-link :to="`/product/${item._id}`">{{
          item.productName
        }}</router-link>
      </h4>
      <span class="rating">
        <v-rating
          readonly
          :value="item.rating"
          class="mr-2"
          size="20"
          color="orange"
          background-color="orange"
        ></v-rating>
        ({{ item.reviews }} reviews)
      </span>
      <h2 v-if="item.discountPercentage != 0">
        <strike>
         Rs. {{ numberFormat(item.price - item.discountPercentage * 100) }}</strike
        >
        Rs. {{ numberFormat(item.price - item.discountPercentage * 100) }}
      </h2>
      <h2 v-if="item.discountPercentage == 0">
        Rs. {{ numberFormat(item.price) }}
      </h2>
      <span class="borderBox"></span>
      <ul class="footer">
        <li>
          Brand : <strong>{{ item.brand }}</strong>
        </li>
        <li v-if="item.year">
          Year : <strong>{{ item.year }}</strong>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ProductCard",
  data: () => ({
    loading: false,
  }),
  props: ["item"],
  computed: {
    ...mapGetters(["allLogin"]),
  },
  methods: {
    ...mapActions(["postWishlist"]),
    numberFormat(givenNumber) {
      let internationalNumberFormat = new Intl.NumberFormat("en-IN");
      return internationalNumberFormat.format(givenNumber);
    },
    async addToWishlist() {
      if (this.allLogin == undefined) {
        this.$router.push("/login");
        sessionStorage.setItem("wishlist", this.item._id);
      } else {
        this.loading = true;
        await this.postWishlist({ productId: this.item._id });
        this.loading = false;
        this.item.wishlist = !this.item.wishlist;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.borderBox {
  border-bottom: 1px solid #ededed;
  display: block;
}
.productCardTop {
  border: 2px solid #ededed;
  border-bottom: 0;
  position: relative;
}
img {
  width: 100%;
  object-fit: contain;
  height: 250px;
}
.productCardBody {
  padding: 15px;
  border: 2px solid #ededed;
  h2 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  hr {
    border-bottom: #fff;
  }
  .footer {
    list-style: none;
    padding-top: 10px;
    display: flex;
    padding-left: 0;
    font-size: 14px;
    li {
      margin-right: 10px;
    }
  }
}
a {
  text-decoration: none;
  color: #000;
}
.loadingIcon {
  margin-top: 3px;
  margin-right: 4px;
}
.rating {
  margin: 2px 0 5px;
  display: flex;
  font-size: 14px;
  align-items: center;
  margin-left: -5px;
}
.productCardTop {
  position: relative;
  height: 250px;
  .heart {
    position: absolute;
    right: 15px;
    top: 15px;
    color: #000;
    padding: 3px;
    border-radius: 50%;
    background: #fff;
    &.filled {
      color: red;
    }
  }
}
strike {
  font-size: 14px;
  color: #8c8c8c;
}
</style>