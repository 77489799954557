<template>
  <section>
    <v-skeleton-loader
      v-for="index in image"
      :key="index + 'image'"
      class="mx-auto"
      height="100"
      type="image"
    ></v-skeleton-loader>

    <v-skeleton-loader
      v-for="index in title"
      :key="index + 'title'"
      class="mx-auto mb-2"
      height="30"
      type="image"
    ></v-skeleton-loader>

    <v-skeleton-loader
      v-for="index in text"
      :key="index + 'text'"
      class="mx-auto mb-2"
      height="10"
      type="image"
    ></v-skeleton-loader>

    <v-skeleton-loader
      v-for="index in button"
      :key="index + 'button'"
      class="mx-auto mt-4"
      height="40"
      type="image"
    ></v-skeleton-loader>
  </section>
</template>

<script>
export default {
  name: "SkeletonLoading",
  props: ["image", "title", "text", "button"],
};
</script>

<style>
</style>